import { 
    GET_ALL_POSTS,
    GET_POST
} from '../actions/types';

const initialState = {
    currentPost: {},
    posts: [],
    post: {},
    ok: false,
    message: '',
    errors: []
};

export default function (state = initialState, action) {
    switch(action.type) {
        case GET_POST:
            return {
                ...state,
                currentPost: action.payload.post,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors
            }
        case GET_ALL_POSTS:
            return {
                ...state,
                posts: action.payload.data,
                ok: action.payload.ok,
                message: action.payload.message,
                errors: action.payload.errors
            }
        default: 
            return state;
    }
}
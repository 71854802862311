import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions

// Style
import '../../styles/components/spacer/spacer.scss';

// Assets

class PriceSpacer extends Component {
    render() {
        return (
            <span className="PriceSpacer" />
        );
    }
}

export default connect(null, { })(PriceSpacer);
import { 
    GET_ALL_POSTS,
    GET_POST
} from './types';

export const getPostById = (id) => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/get/${id}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(post => {
            dispatch({
                type: GET_POST,
                payload: post
            });

            if(post.ok) {
                resolve(post);
            } else {
                reject(post);
            }
        });
    });
};

export const getPosts = (page, page_size, filters) => dispatch => {
    let query = ``;

    if(Object.keys(filters).length > 0) {
        for (const key in filters) {
            if (Object.hasOwnProperty.call(filters, key)) {
                const element = filters[key];
                query = `${query}&${key}=${element}`;
            }
        }
    }

    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/blog/?page=${page}&page_size=${page_size}${query}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(post => {
            dispatch({
                type: GET_ALL_POSTS,
                payload: post
            });

            if(post.ok) {
                resolve(post);
            } else {
                reject(post);
            }
        });
    });
};
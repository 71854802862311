import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions

// Style
import '../../styles/components/spacer/spacer.scss';

// Assets

class ParagraphSpacer extends Component {
    render() {
        return (
            <div className="ParagraphSpacer" />
        );
    }
}

export default connect(null, { })(ParagraphSpacer);
import React, { Component } from 'react';

// Assets

// Components
import Navbar from '../components/navbar/navbar.component';
import NavbarMobile from '../components/navbar/navbarMobileHeader.component';
import LoginButton from '../components/block/loginButton';
import HomeHeader from '../components/header/home.header';
import ParagraphSpacer from '../components/spacers/paragraph.spacer';
import BlockSpacer from '../components/spacers/block.spacer';
import PostList from '../components/post/postList';
import Footer from '../components/footer/footer';

class Home extends Component {
    
    render() {
        return (
            <div className="container">
                <Navbar />
                <main className="fixedBackground">
                    <NavbarMobile />
                    <LoginButton />
                    <ParagraphSpacer />
                    <HomeHeader />
                    <ParagraphSpacer />
                    <BlockSpacer />
                    <PostList />
                    <BlockSpacer />
                    <Footer />
                    <ParagraphSpacer />
                </main>
            </div>
        );
    }
}

export default Home;

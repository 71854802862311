import { 
    SET_IS_MOBILE_MENU_OPEN,
    SET_IS_MOBILE_SITE,
    CREATE_CONTACT
} from './types';

export const setIsMobileSite = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_IS_MOBILE_SITE,
            payload: data
        });

        resolve(data);
    });
}

export const setIsMobileMenuOpen = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        dispatch({
            type: SET_IS_MOBILE_MENU_OPEN,
            payload: data
        });

        resolve(data);
    });
}

export const sendContactRequest = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API_URL}/api/v1/contacts/create`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(res => res.json())
        .then(post => {

            dispatch({
                type: CREATE_CONTACT,
                payload: post
            });

            if(!post.ok) {
                reject(post);
            } else {
                resolve(post);
            }
        });
    });
};

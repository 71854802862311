import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavHashLink } from 'react-router-hash-link';

// Actions
import { setIsMobileMenuOpen } from '../../actions/utility.action';

// Style
import '../../styles/components/navbar/navbar.scss';

// Assets
import logo from '../../assets/web_assets/all_pages_assets/ppa-logo.png';
import Socials from '../../assets/files/socials';

class Navbar extends Component {

    constructor(props) {
        super(props);

        this.openClose = this.openClose.bind(this);
        this.navigateTo = this.navigateTo.bind(this);
    }

    openClose() {
        this.props.setIsMobileMenuOpen(!this.props.utility.isMobileMenuOpen);
    }

    navigateTo() {
        window.scrollTo(0,0);
        this.props.setIsMobileMenuOpen(false);
    }


    render() {
        let renderSocials = Socials.map((value, index) => (
            <li 
                key={`navbar_socials_${index}`}
                className={`nav-item`}
            >
                <a 
                    href={value.url}
                    className="nav-link"
                >
                    {value.whiteIcon}
                </a>
            </li>
        ));

        return (
            <nav className={this.props.utility.isMobileMenuOpen ? `navbar open` : "navbar"}>
                <ul className="navbar-nav">
                    <div className="header">
                        <li className="logo">
                            <NavHashLink 
                                to="/" 
                                className="nav-link" 
                                onClick={() => this.navigateTo()}
                                exact
                            >
                                <img src={logo} alt={`PPA Logo`} />
                            </NavHashLink>
                        </li>

                        <li className="hamburger-button">
                            <button
                                type="button"
                                className="nav-link"
                                onClick={() => this.openClose()}
                            >
                                &times;
                            </button>
                        </li>
                    </div>
                    

                    <li className={`nav-item`}>
                        <NavHashLink to="/" className="nav-link" onClick={() => this.navigateTo()} exact>
                            <span className="link-text">Home</span>
                        </NavHashLink>
                    </li>
                    <li className={`nav-item dropdown ${window.location.pathname === '/products' ? 'expand' : ''}`}>
                        <NavHashLink 
                            to="/products" 
                            className={`nav-link ${window.location.pathname === '/products' ? 'active' : ''}`} 
                            onClick={() => this.navigateTo()}
                            exact={true}
                            smooth={true}
                        >
                            <span className="link-text">Products</span>
                        </NavHashLink>
                        <div className="dropdown">
                            <ul className="dropdown-list">
                                <li className="dropdown-item">
                                    <NavHashLink 
                                        to="/products#matchCoding" 
                                        className="nav-link" 
                                        onClick={() => this.navigateTo()} 
                                        exact={true}
                                        smooth={true}
                                    >
                                        <span className="link-text">Match Coding</span>
                                    </NavHashLink>
                                </li>
                                <li className="dropdown-item">
                                    <NavHashLink 
                                        to="/products#matchReports" 
                                        className="nav-link" 
                                        onClick={() => this.navigateTo()} 
                                        exact={true}
                                        smooth={true}
                                    >
                                        <span className="link-text">Match Reports</span>
                                    </NavHashLink>
                                </li>
                                <li className="dropdown-item">
                                    <NavHashLink 
                                        to="/products#gps" 
                                        className="nav-link" 
                                        onClick={() => this.navigateTo()} 
                                        exact={true}
                                        smooth={true}
                                    >
                                        <span className="link-text">GPS</span>
                                    </NavHashLink>
                                </li>
                                <li className="dropdown-item">
                                    <NavHashLink 
                                        to="/products#presentations" 
                                        className="nav-link" 
                                        onClick={() => this.navigateTo()} 
                                        exact={true}
                                        smooth={true}
                                    >
                                        <span className="link-text">Presentations</span>
                                    </NavHashLink>
                                </li>
                                <li className="dropdown-item">
                                    <NavHashLink 
                                        to="/products#trends" 
                                        className="nav-link" 
                                        onClick={() => this.navigateTo()} 
                                        exact={true}
                                        smooth={true}
                                    >
                                        <span className="link-text">Trends</span>
                                    </NavHashLink>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li className={`nav-item`}>
                        <NavHashLink to="/testimonials" className="nav-link" onClick={() => this.navigateTo()} exact>
                            <span className="link-text">Testimonials</span>
                        </NavHashLink>
                    </li>
                    <li className={`nav-item`}>
                        <NavHashLink to="/contact" className="nav-link" onClick={() => this.navigateTo()} exact>
                            <span className="link-text">Contact Us</span>
                        </NavHashLink>
                    </li>

                    <div className="socials">
                        {renderSocials}
                    </div>
                </ul>
            </nav>
        );
    }
}

const mapStateToProps = state => ({
    utility: state.utility
});

export default connect(mapStateToProps, { 
    setIsMobileMenuOpen
})(Navbar);
import React, { Component } from 'react';

import Header from './header';

class ContactHeader extends Component {
    
    render() {
        return (
            <Header 
                title={(<h1>Contact Us</h1>)}
            />
        );
    }
}

export default ContactHeader;

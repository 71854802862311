import React, { Component } from 'react';

// Assets

// Components
import Navbar from '../components/navbar/navbar.component';
import NavbarMobile from '../components/navbar/navbarMobileHeader.component';
import LoginButton from '../components/block/loginButton';
import ParagraphSpacer from '../components/spacers/paragraph.spacer';
import BlockSpacer from '../components/spacers/block.spacer';
import FullPost from '../components/post/fullPost';
import Footer from '../components/footer/footer';

class BlogPost extends Component {
    
    render() {
        return (
            <div className="container">
                <Navbar />
                <main className="fixedBackground">
                    <NavbarMobile />
                    <LoginButton />
                    <ParagraphSpacer />
                    <FullPost />
                    <BlockSpacer />
                    <Footer />
                    <ParagraphSpacer />
                </main>
            </div>
        );
    }
}

export default BlogPost;

import React, { Component } from 'react';

// Assets
/*
import shaneLayden from '../assets/web_assets/testimonals_assets/player-shane-layden.jpg';
import georgeHannigan from '../assets/web_assets/testimonals_assets/player-george-hannigan.jpg';
import jpCooney from '../assets/web_assets/testimonals_assets/player-jp_cooney.png';
import brendanMcKeogh from '../assets/web_assets/testimonals_assets/coach-brendan-mckeogh.jpg';
import ciaranComerford from '../assets/web_assets/testimonals_assets/coach-Ciaran_Comerford.jpg';
*/

// Components
import Navbar from '../components/navbar/navbar.component';
import NavbarMobile from '../components/navbar/navbarMobileHeader.component';
import LoginButton from '../components/block/loginButton';
import TestimonialHeader from '../components/header/testimonials.header';
import ParagraphSpacer from '../components/spacers/paragraph.spacer';
import BlockSpacer from '../components/spacers/block.spacer';
import Footer from '../components/footer/footer';
import ContentBlock from '../components/block/contentBlock';

class Testimonials extends Component {
    
    render() {
        return (
            <div className="container">
                <Navbar />
                <main className="fixedBackground">
                    <NavbarMobile />
                    <LoginButton />
                    <ParagraphSpacer />
                    <TestimonialHeader />
                    <ParagraphSpacer />
                    <BlockSpacer />
                    <ContentBlock 
                        header={(<h2>Players Point of View</h2>)}
                        titleLeft={(<h3>Shane Layden</h3>)}
                        titleRight={(<h4>Captain of Buccaneers RFC & Former Ireland 7's Player</h4>)}
                        // // imgSrc={shaneLayden}
                        imgAlt={`Shane Layden`}
                        content={(
                            <>
                                <p>“The ability to see your individual performances and team set pieces less than 24 hours after the game has allowed us to learn and review games like professionals. Using the Hudl app and YouTube links we have been able to review/preview and have online conversations with coaches and other players.</p>
                                <p>Through the individual clips we can see through Michaels eyes what we are doing well or what we need to work on. The quick turnaround allows us To park the long team review and focus on the trends formed from the code. Each involvement is coded either positive or negative allowing the player to see where he is adding impact or not to the game. It’s brilliant for the amateur player that wants to improve”</p>
                            </>
                        )}
                    />
                    <BlockSpacer />
                    <ContentBlock 
                        titleLeft={(<h3>George Hannigan</h3>)}
                        titleRight={(<h4>Former Tipperary Footballer & Shannon Rovers Hurler</h4>)}
                        // imgSrc={georgeHannigan}
                        imgAlt={`George Hannigan`}
                        content={(
                            <>
                                <p>“Michael’s input contributed greatly to both team and player development this year. The video analysis was provided in a timely and efficient manner. Most importantly, the clips were concise and to the point providing management and players with key information after each game. The accompanying data was also presented in a format that was easy to comprehend and as a result gave us the opportunity to work on certain aspects of our play. I’d highly recommend using such a service as huge learning can take place in a short period of time, such is the format in which Michael displays both the video analysis and accompanying data”</p>
                            </>
                        )}
                    />
                    <BlockSpacer />
                    <ContentBlock 
                        header={(<h2>The Coach's Point of View</h2>)}
                        titleLeft={(<h3>JP Cooney</h3>)}
                        titleRight={(<h4>Senior Head Coach of Galway Corinthians RFC, Former Professional Connacht Rugby Player</h4>)}
                        // imgSrc={jpCooney}
                        imgAlt={`JP Cooney`}
                        content={(
                            <>
                                <p>“I first was introduced to Michael earlier this year and was happy to have him as part of our coaching ticket, both carrying out in-depth analysis of our game as well as player development/skills coach. Michael instantly added value by looking at our games from the previous year and spotting trends in our game – both what we were strong at as well as areas for improvement. Not only is this offered on a team level across the whole squad but also details targeted at the individual. This allows us, at Corinthians, to not only improve and develop our systems as a club but allows us to develop our players as individuals by addressing the areas which need improvement – and also quantifying how they can be improved by defined metrics. When coupling this with real time information gathered from training sessions through GPS units, it allows us to tailor our training to get the most out of every training session.</p>
                                <p>This, added to Michaels outstanding knowledge of the game, gives us an environment of feedback and analysis that is on a par with any professional environment. From my years as a professional player with Connacht, I am delighted that Corinthians can provide pretty much the same environment for our players to grow, learn and develop as individuals and as a club”</p>
                            </>
                        )}
                    />
                    <BlockSpacer />
                    <ContentBlock 
                        titleLeft={(<h3>Brendan McKeogh</h3>)}
                        titleRight={(<h4>Director of Sports at Cistercian College Roscrea</h4>)}
                        // imgSrc={brendanMcKeogh}
                        imgAlt={`Brendan McKeogh`}
                        content={(
                            <>
                                <p>“Michael has been our Rugby Video Analyst for 2 years. During that time Michael has added significantly to our rugby programme.</p>
                                <p>Michael uses Hudl to develop player and management interaction with the match footage. Each player would have access to their own individual footage and team play within 24 hours. This gives the squad and management an opportunity to go over the strengths and weaknesses of the previous game at the next training session.</p>
                                <p>Michael is also doing one to one meetings with our players. This is an interactive process where both Michael and the player contribute on their individual strengths and weaknesses. This individual player development is equally as important as the overall team development.</p>
                                <p>Through the match coding Michael is able to give the management a detailed and direct post match statistical breakdown of the game. This is invaluable when seeing trends over a season. It gives an easily accessible trend as to where a team is progressing or regressing.</p>
                                <p>We would have no hesitation in recommending this service to any team and feel the results will speak for themselves”</p>
                                <p>Through the individual clips we can see through Michaels eyes what we are doing well or what we need to work on. The quick turnaround allows us To park the long team review and focus on the trends formed from the code. Each involvement is coded either positive or negative allowing the player to see where he is adding impact or not to the game. It’s brilliant for the amateur player that wants to improve”</p>
                            </>
                        )}
                    />
                    <BlockSpacer />
                    <ContentBlock 
                        titleLeft={(<h3>Ciarán Comerford</h3>)}
                        titleRight={(<h4>Manager Of Roscommon Hurlers and Borris Killcotton GAA Senior Hurlers</h4>)}
                        // imgSrc={ciaranComerford}
                        imgAlt={`Ciarán Comerford`}
                        content={(
                            <>
                                <p>“From having played rugby with and coached by Michael I knew his attention to detail was second to none in relation to how he played the game and how he wanted the game to be played. So, when I heard Michael had setup a sports performance business it was an easy decision for me to enlist his services. Albeit in a different code – hurling.</p>
                                <p>The analysis – a discussion was had before the very first game was broken down on what I as the coach wanted. And every game thereafter I am asked if I would like anything done differently or other parameters measured. Very much a bespoke service in this sense as each coach/manager wants to get the most out of their players but are very different in how they want to go about it. Each player receives their clips of the game and any other clips I see as a possible area of improvement for the team as a collective. Each player reviews the clips and comes to training the following night with 3 positives and 3 items to work on.</p>
                                <p>Game reviews collectively have been made a lot less time consuming as I no longer have to rifle through hours of footage when within minutes Michael can pull what I require straight from Sportscode/Hudl. Putting the presentation together with coaching points, sequences of play and any other facets of the game that I require.</p>
                                <p>Over the past 2 seasons Michael’s analysis has allowed me to prosper as a coach/manager and it has certainly given my players the confidence to self-reflect without seeing analysis as being a negative, hence being able to control the controllable”.</p>
                            </>
                        )}
                    />
                    <BlockSpacer />
                    <Footer />
                    <ParagraphSpacer />
                </main>
            </div>
        );
    }
}

export default Testimonials;

export const FETCH_POSTS = 'FETCH_POSTS';
export const NEW_POST = 'NEW_POST';
export const SELECT_POST = 'SELECT_POST';
export const CLEAR_POST_FORM = 'CLEAR_POST_FORM';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const SET_IS_MOBILE_MENU_OPEN = 'SET_IS_MOBILE_MENU_OPEN';
export const SET_IS_MOBILE_SITE = 'SET_IS_MOBILE_SITE';
export const CREATE_CONTACT = 'CREATE_CONTACT';
export const GET_ALL_POSTS = 'GET_ALL_POSTS';
export const GET_POST = 'GET_POST';
import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions

// Style
import '../../styles/components/contentBlock/loginButton.scss';

// Assets
import { Trends } from '../../assets/files/misc';

class LoginButton extends Component {

    render() {

        return (
            <div className="loginButton-container">
                <a
                    href={Trends}
                    className="btn-login"
                >
                    <span className="large">Login</span>
                    <br />
                    To Trends
                </a>
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { 

})(LoginButton);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

// Actions
import { sendContactRequest } from '../../actions/utility.action';

// Style
import '../../styles/components/contact/contactContainer.scss';

// Assets
import ppaLogoCircle from '../../assets/web_assets/contact_us_assets/ppa-logo-circle.jpg';
import Socials from '../../assets/files/socials';

// Components
import { ContactForm } from './contact.form';

class ContactContainer extends Component {

    constructor(props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit(data) {
        console.log(data);
        this.props.sendContactRequest(data)
        .then(res => {
            Swal.fire('Message Sent!', res.message, 'success');
        })
        .catch(res => {
            Swal.fire('Error sending message!', res.message, 'error');
        });
    }

    render() {
        let socialsItems = Socials.map((value, index) => (
            <li 
                key={`social-item-${index}`}
                className={`social-item ${(index === 1) ? 'middle' : ''}`}
            >
                <a
                    href={value.url}
                    className={`social-link`}
                >
                    <img src={value.img} alt={value.imgAlt} />
                </a>
            </li>
        ));

        return (
            <div className="contactContainer">
                <div className="col left">
                    <div className="title-container">
                        <div className="title">
                            <h2>About Us</h2>
                        </div>
                    </div>
                    <div className="content-container">
                        <div className="content">
                            <p>In my early years of coaching rugby I started to record games, using some very simple tools I started to breakdown the games to provide feedback to the players on ways to improve as individual’s and the team as a whole. Video became a hugely powerful tool for player improvement.</p>
                            <p>As the seasons passed I began coding the games in much more detail with better software, PPA now uses Hudl Sportscode. This involved much more data driven coding derived from game footage and GPS data. PPA code’s every single thing every player does and grades it for effectiveness. By using Microsoft Excel I could create an Effectiveness Score spreadsheet and dashboards which could track all the players through their skills sets and find trends or bad habits within these.</p>
                            <p>As PPA grew and collected more data from various clubs and players we felt the need to be able to provide this feedback on an accessible, easy to use platform which led to the core PPA tool of “Trends”.</p>
                            <p>PPA Trends is a vital tool to coaches and players as they can access all the collected data on an easy to use, mobile phone friendly website. Here is where you will find the dashboards and filters which allows a person to quickly filter down to an individual and all the data is colour coded with Effectiveness Scores.</p>
                            <p>At PPA we feel that the Trends tool sets us apart. I also believe how we prepare game review presentations is of the highest standard, we get to know the coach’s and bring his/her voice to the videos.</p>
                            <img src={ppaLogoCircle} alt={`Alternative PPA Logo`} />
                        </div>
                    </div>
                    <div className="socials">
                        <div className="title">
                            <h2>Add Us On Social Media</h2>
                        </div>
                        <ul className="social-list">
                            {socialsItems}
                        </ul>
                    </div>
                </div>
                <div className="col right">
                    <div className="title-container">
                        <div className="title">
                            <h2>Get In Touch</h2>
                        </div>
                    </div>
                    <div className="form-container">
                        <ContactForm 
                            onSubmit={this.onSubmit}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { 
    sendContactRequest
})(ContactContainer);
import React, { Component } from 'react';

// Assets
import { Trends } from '../assets/files/misc';

// Components
import Navbar from '../components/navbar/navbar.component';
import NavbarMobile from '../components/navbar/navbarMobileHeader.component';
import LoginButton from '../components/block/loginButton';
import ProductHeader from '../components/header/product.header';
import ParagraphSpacer from '../components/spacers/paragraph.spacer';
import BlockSpacer from '../components/spacers/block.spacer';
import PriceSpacer from '../components/spacers/price.spacer';
import Footer from '../components/footer/footer';
import ContentBlock from '../components/block/contentBlock';

class Products extends Component {
    
    render() {
        return (
            <div className="container">
                <Navbar />
                <main className="fixedBackground">
                    <NavbarMobile />
                    <LoginButton />
                    <ParagraphSpacer />
                    <ProductHeader />
                    <ParagraphSpacer />
                    <BlockSpacer />
                    <ContentBlock 
                        id={'matchCoding'}
                        titleLeft={(<h3>Match Coding</h3>)}
                        titleRight={(<h4>Price <PriceSpacer />From €125.00 / Game</h4>)}
                        videoSrc={`https://www.youtube.com/embed/YN5O--cIO_A`}
                        content={(
                            <>
                                <p>The club/team record the games, send the footage to PPA to code. We will have met the coach and have built a code window for the team. This helps PPA break down the game in the vision and understanding of each individual team. We break the footage down by set piece and player. Every single thing a player does is coded and rated for effectiveness ; i.e tackling in rugby – was the tackle a chop / dominant tackle (effective) or soak / mis-tackle (ineffective).</p>
                            </>
                        )}
                    />
                    <BlockSpacer />
                    <ContentBlock 
                        id={'matchReports'}
                        titleLeft={(<h3>Match Reports</h3>)}
                        titleRight={(<h4>Price <PriceSpacer />€10.00 / Game</h4>)}
                        videoSrc={`https://www.youtube.com/embed/w4L3ERMdORk`}
                        content={(
                            <>
                                <p>PPA can generate a selection of match reports as soon as the game is coded. This helps makes the data from the coding presentable, understandable and easy to share.</p>
                            </>
                        )}
                    />
                    <BlockSpacer />
                    <ContentBlock
                        id={'gps'}
                        titleLeft={(<h3>GPS</h3>)}
                        titleRight={(<h4>Price <PriceSpacer />From €50.00 / Session</h4>)}
                        videoSrc={`https://www.youtube.com/embed/K7Q4PhAMc2g`}
                        content={(
                            <>
                                <p>PPA uses Statsports Apex GPS units to hep coaches get a better understanding of training and games. We help coaches rate and plan training through our understanding of match demands in the key areas of: metres per minute, HMLD, HML Efforts, Sprint data and total distance. We have built our own report windows to feed back all the complex data in a few easy to understand numbers.</p>
                            </>
                        )}
                    />
                    <BlockSpacer />
                    <ContentBlock 
                        id={'presentations'}
                        titleLeft={(<h3>Presentations</h3>)}
                        titleRight={(<h4>Price <PriceSpacer />€25.00</h4>)}
                        videoSrc={`https://www.youtube.com/embed/eFzJvd01GEc`}
                        content={(
                            <>
                                <p>After games when all the reports and coded footage is sent back to the coaches and players we will contact the coach and have an in depth chat to review the game. From here PPA will build short presentation videos with the coaches tactical and coaching points in the video. This video can be easily shared on such applications as WhatsApp or sent directly to the coach to present to the players in person. This is a huge time saving service.</p>
                            </>
                        )}
                    />
                    <BlockSpacer />
                    <ContentBlock 
                        id={'trends'}
                        titleLeft={(<h3>Trends</h3>)}
                        titleRight={(<h4>Price <PriceSpacer />€30.00 / Game</h4>)}
                        videoSrc={`https://www.youtube.com/embed/iU87_tZhoMU`}
                        content={(
                            <>
                                <p>Trends is our platform where all the numbers and data are stored. The numbers are from the matches coded. As PPA code every single thing we generate a large amount of numbers. Trends pull these numbers into their individual skill set and puts an effectiveness score on that players skill sets. In a few clicks of the filters a player can see areas of the game that needs to be worked on. </p>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flex: '1 1 100%',
                                    justifyContent: 'center',
                                    padding: '1rem 0'
                                }}>
                                    <a
                                        href={Trends}
                                        className="btn-primary"
                                    >
                                        View Trends
                                    </a>
                                </div>
                            </>
                        )}
                    />
                    <BlockSpacer />
                    <Footer />
                    <ParagraphSpacer />
                </main>
            </div>
        );
    }
}

export default Products;

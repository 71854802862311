import React, { Component } from 'react';

import Header from './header';

class HomeHeader extends Component {
    
    render() {
        return (
            <Header 
                title={(<h1>Player Performance Analysis</h1>)}
                content={(<p>At PPA our aim is to help coaches give their players more individual feedback through our coding, stats and GPS Tracking. We use “EFFECTIVENESS” as a way to evaluate players when we fully code games. Each Player gets an “EFFECTIVENESS SCORE”. This helps us track trends in all the players skill set. Coded videos are shared on Youtube or Hudl. We use Sportscode and Apex GPS units</p>)}
            />
        );
    }
}

export default HomeHeader;

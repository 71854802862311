import React from 'react';
import './styles/App.scss';
import { Provider } from 'react-redux';
import { Switch, Route, Router } from 'react-router-dom';
// import ProtectedRoute from './components/protectedRoute.component';
import { store } from './store';
import history from './history';

// Library Styles
import 'dg-forms/dist/index.css';

// Home
import Home from './pages/home';
import Contact from './pages/contact';
import Products from './pages/products';
import Testimonials from './pages/testimonials';
import BlogPost from './pages/blogPost';

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/contact' exact component={Contact} />
          <Route path='/products' exact component={Products} />
          <Route path='/testimonials' exact component={Testimonials} />
          <Route path='/post/:id' exact component={BlogPost} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;

import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions

// Style
import '../../styles/components/header/header.scss';

// Assets

class Header extends Component {

    render() {

        return (
            <div className="header">
                {
                    this.props.title ? (
                        <div className="title-container">
                            <div className="title" style={this.props.title && this.props.content === undefined ? {padding: 'unset'} : {}}>
                                {this.props.title}
                            </div>
                        </div>
                    ) : ''
                }
                {
                    this.props.content ? (
                        <div className="content-container">
                            <div className="content" style={this.props.content && this.props.title === undefined ? {padding: 'unset'} : {}}>
                                {this.props.content}
                            </div>
                        </div>
                    ) : ''
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { 

})(Header);
import React, { Component } from 'react';

import Header from './header';

class TestimonialHeader extends Component {
    
    render() {
        return (
            <Header 
                title={(<h1>Testimonials</h1>)}
                content={(<p>Providing coaches with feedback and data to improve players on an individual basis</p>)}
            />
        );
    }
}

export default TestimonialHeader;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import loginReducer from './login.reducer';
import utilityReducer from './utility.reducer';
import postReducer from './posts.reducer';

const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    loggedInUser: loginReducer,
    form: formReducer,
    utility: utilityReducer,
    posts: postReducer
});

export default createRootReducer;
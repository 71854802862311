import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// Actions

// Style
import '../../styles/components/footer/footer.scss';

// Assets

class Footer extends Component {

    render() {

        return (
            <div className={`footer ${window.location.pathname === '/contact' ? 'contact': ''}`}>
                <ul>
                    <li>
                        <Link
                            to={`/`}
                            className=""
                        >
                            T&C
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={`/`}
                            className="footer-link middle"
                        >
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link
                            to={`/`}
                            className=""
                        >
                            Cookies
                        </Link>
                    </li>
                    <li>
                        <a
                            href={`https://digigrow.ie`}
                            className="footer-link middle"
                        >
                            Designed & Developed by Digigrow
                        </a>
                    </li>
                </ul>
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { 

})(Footer);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

// Actions
import { getPosts } from '../../actions/post.action';

// Style
import '../../styles/components/post/postList.scss';

// Assets
import holderImg from '../../assets/web_assets/home_assets/post1-sixnations.jpg';

// Components
import Post from './post';

class PostList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            query: {
                status: 'Published'
            },
            page: 0,
            page_size: 5,
            total_pages: 0,
            total_items: 0,
            num_pages_to_add: 5
        };

        this.paginate = this.paginate.bind(this);
    }

    componentDidMount() {
        this.paginate(this.state.page, this.state.page_size);
    }

    paginate(page, page_size) {
        this.props.getPosts(page, page_size, this.state.query)
        .then(res => {
            this.setState({
                page: res.page,
                page_size: res.page_size,
                total_pages: res.total_pages,
                total_items: res.total_items
            });
        })
        .catch(res => {
            Swal.fire(`Error`, res.message, 'error');
        });
    }

    render() {
        let renderPosts = [];

        if(
            this.props.posts &&
            this.props.posts.posts &&
            this.props.posts.posts.length > 0
        ) {
            renderPosts = this.props.posts.posts.map((value, index) => (
                <Post 
                    key={`posts_${index}`}
                    title={(<h3>{value.title}</h3>)}
                    imgSrc={value.header_image || holderImg}
                    imgAlt={value.title}
                    date={(<p>{new Date(value.createdAt).toLocaleDateString('en-us', { 
                        day: 'numeric', 
                        month: 'short',
                        year: 'numeric'
                    })}</p>)}
                    content={<p>{value.summary}</p>}
                    postUrl={`/post/${value._id}`}
                    showSharingOptions={true}
                    type={
                        value.files &&
                        value.files.length > 0 &&
                        value.files[0] &&
                        value.files[0].contentType ?
                        value.files[0].contentType : ''
                    }
                />
            ));
        } 

        return (
            <div className="PPAPost-List">
                {renderPosts}
                {
                    this.props.posts &&
                    this.props.posts.posts &&
                    this.props.posts.posts.length > 0 &&
                    this.state.total_items > this.state.page_size ? (
                        <div className="load-more">
                            <button
                                type="button"
                                className="btn-link"
                                onClick={() => this.paginate(this.state.page, this.state.page_size + this.state.num_pages_to_add)}
                            >
                                Load More Posts
                            </button>
                        </div>
                    ) : ''
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    posts: state.posts
});

export default connect(mapStateToProps, { 
    getPosts
})(PostList);
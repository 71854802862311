import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions

// Style
import '../../styles/components/contentBlock/contentBlock.scss';

// Assets

class ContentBlock extends Component {

    render() {

        return (
            <div className="contentBlock" id={this.props.id}>
                {
                    this.props.header ? (
                        <div className="header">
                            {this.props.header}
                        </div>
                    ) : ''
                }
                {
                    this.props.titleLeft ||
                    this.props.titleRight ? (
                        <div className="title-container">
                            <div className="title">
                                <div className="left">
                                    {this.props.titleLeft ? this.props.titleLeft : ''}
                                </div>
                                <div className="right">
                                    {this.props.titleRight ? this.props.titleRight : ''}
                                </div>
                            </div>
                        </div>
                    ) : ''
                }
                {
                    this.props.imgSrc ? (
                        <div className="img-container">
                            <div className="img">
                                <img src={this.props.imgSrc} alt={this.props.imgAlt || ''} />
                            </div>
                        </div>
                    ) : ''
                }
                {
                    this.props.videoSrc ? (
                        <div className="vid-container">
                            <div className="vid">
                                <iframe 
                                    title={`youtube_video_0_${this.props.titleLeft}`}
                                    width="560" 
                                    height="315" 
                                    src={this.props.videoSrc} 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                    allowFullScreen
                                />
                            </div>
                        </div>
                    ) : ''
                }
                {
                    this.props.content ? (
                        <div className="content-container">
                            <div className="content">
                                {this.props.content} 
                            </div>
                        </div>
                    ): ''
                }
                
            </div>
        );
    }
}

const mapStateToProps = state => ({

});

export default connect(mapStateToProps, { 

})(ContentBlock);
import { 
    SET_IS_MOBILE_MENU_OPEN,
    SET_IS_MOBILE_SITE,
    CREATE_CONTACT
 } from '../actions/types';

const initialState = {
    isMobileSite: false,
    isMobileMenuOpen: false,
    ok: false,
    message: '',
    access_token: '',
    errors: []
};

export default function (state = initialState, action) {
    switch(action.type) {
        case SET_IS_MOBILE_MENU_OPEN: 
            return {
                ...state,
                isMobileMenuOpen: action.payload
            }
        case SET_IS_MOBILE_SITE: 
            return {
                ...state,
                isMobileSite: action.payload
            }
        case CREATE_CONTACT: 
            return {
                ...state,
                ok: action.payload.ok,
                message: action.payload.message,
                access_token: action.payload.access_token,
                errors: action.payload.errors,
            }
        default: 
            return state;
    }
}
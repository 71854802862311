import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';

// Actions
import { getPostById } from '../../actions/post.action';

// Style
import '../../styles/components/post/fullPost.scss';

// Assets
import holderImg from '../../assets/web_assets/home_assets/post1-sixnations.jpg';

// Components
import Post from './post';

class FullPost extends Component {

    componentDidMount() {
        let urlParams = window.location.pathname;
        let params = urlParams.split('/');

        this.props.getPostById(params[2])
        .catch(res => {
            Swal.fire(`Error`, res.message, 'error');
        });
    }

    render() {

        return (
            <div className="PPAPost-FullPost">
                <Helmet>
                    <meta property="og:url" content={window.location.href} />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content={this.props.posts.currentPost.title} />
                    <meta property="og:description" content={this.props.posts.currentPost.summary} />
                    <meta property="og:image" content={
                        this.props.posts.currentPost &&
                        this.props.posts.currentPost.header_image &&
                        this.props.posts.currentPost.header_image !== '' ? 
                        this.props.posts.currentPost.header_image : holderImg} />
                    <meta name="twitter:title" content={this.props.posts.currentPost.title} />
                    <meta name="twitter:description" content={this.props.posts.currentPost.summary} />
                    <meta name="twitter:image" content={
                        this.props.posts.currentPost &&
                        this.props.posts.currentPost.header_image &&
                        this.props.posts.currentPost.header_image !== '' ? 
                        this.props.posts.currentPost.header_image : holderImg} />
                    <meta name="twitter:card" content={this.props.posts.currentPost.summary} />
                </Helmet>
                {
                    this.props.posts &&
                    this.props.posts.currentPost &&
                    this.props.posts.currentPost._id ? (
                        <Post 
                            title={(<h3>{this.props.posts.currentPost.title}</h3>)}
                            imgSrc={this.props.posts.currentPost.header_image || holderImg}
                            imgAlt={this.props.posts.currentPost.title}
                            date={(<p>{new Date(this.props.posts.currentPost.createdAt).toLocaleDateString('en-gb')}</p>)}
                            content={parse(this.props.posts.currentPost.wysiwyg)}
                            postUrl={`/post/${this.props.posts.currentPost._id}`}
                            showSharingOptions={true}
                            type={
                                this.props.posts.currentPost &&
                                this.props.posts.currentPost.files &&
                                this.props.posts.currentPost.files.length > 0 &&
                                this.props.posts.currentPost.files[0] &&
                                this.props.posts.currentPost.files[0].contentType ?
                                this.props.posts.currentPost.files[0].contentType : ''
                            }
                        />
                    ) : ''
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
    posts: state.posts
});

export default connect(mapStateToProps, { 
    getPostById
})(FullPost);
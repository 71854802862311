import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

// Actions
import { setIsMobileMenuOpen } from '../../actions/utility.action';

// Style
import '../../styles/components/navbar/navbarMobile.scss';

// Assets
import logo from '../../assets/web_assets/all_pages_assets/ppa-logo.png';
import { Trends } from '../../assets/files/misc';

class NavbarMobile extends Component {

    constructor(props) {
        super(props);

        this.openClose = this.openClose.bind(this);
    }

    openClose() {
        this.props.setIsMobileMenuOpen(!this.props.utility.isMobileMenuOpen);
    }


    render() {

        return (
            <div className={this.props.utility.isMobileMenuOpen ? `navbar-header hide` : "navbar-header"}>
                <div className="logo">
                    <NavLink to="/" className="nav-link">
                        <img src={logo} alt={`PPA Logo`} />
                    </NavLink>
                </div>

                <div className="hamburger-button">
                    <button
                        type="button"
                        className="nav-link"
                        onClick={() => this.openClose()}
                    >
                        <svg 
                            aria-hidden="true" 
                            focusable="false" 
                            data-prefix="fas" 
                            data-icon="bars" 
                            className="svg-inline--fa fa-bars fa-w-14" 
                            role="img" 
                            xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 448 512"
                        >
                            <path 
                                fill="currentColor" 
                                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                                className="fa-primary"
                            >
                            </path>
                        </svg>
                    </button>
                </div>

                <div className="nav-item">
                    <a 
                        href={Trends} 
                    >
                        Login
                        <br />
                        To Trends
                    </a>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    utility: state.utility
});

export default connect(mapStateToProps, { 
    setIsMobileMenuOpen
})(NavbarMobile);
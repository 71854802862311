import React, { Component } from 'react';
import { connect } from 'react-redux';

// Actions

// Style
import '../../styles/components/spacer/spacer.scss';

// Assets

class BlockSpacer extends Component {
    render() {
        return (
            <div className="BlockSpacer" />
        );
    }
}

export default connect(null, { })(BlockSpacer);
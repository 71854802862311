import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { dgInput, required, email, phoneNumber, dgTextArea } from 'dg-forms';

let Contact_Form = (props) => {
  const { 
    handleSubmit,  
    submitting, 
    onSubmit, 
  } = props; 

  return (
    <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
        <Field
            name="name"
            type="text"
            component={dgInput}
            label="Name"
            labelClass="required"
            validate={[required]}
        />
        <Field
            name="email"
            type="email"
            component={dgInput}
            label="Email"
            labelClass="required"
            validate={[required, email]}
        />
        <Field
            name="phone"
            type="text"
            component={dgInput}
            label="Phone"
            labelClass=""
            validate={[phoneNumber]}
        />
        <Field
            name="message"
            component={dgTextArea}
            label="Notes"
            labelClass="required"
            validate={[required]}
        />

        <button 
            type="submit"
            disabled={submitting}
            className="btn-primary"
        >
            Submit
        </button>
    </form>
  );
};

Contact_Form = reduxForm({
  form: 'Contact_Form'
})(Contact_Form);

Contact_Form = connect(null,{ })(Contact_Form);

export const ContactForm = Contact_Form;
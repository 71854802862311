import React, { Component } from 'react';

// Assets

// Components
import Navbar from '../components/navbar/navbar.component';
import NavbarMobile from '../components/navbar/navbarMobileHeader.component';
import LoginButton from '../components/block/loginButton';
import ContactHeader from '../components/header/contact.header';
import ParagraphSpacer from '../components/spacers/paragraph.spacer';
import BlockSpacer from '../components/spacers/block.spacer';
import ContactContainer from '../components/contact/contact.component';
import Footer from '../components/footer/footer';

class Contact extends Component {
    
    render() {
        return (
            <div className="container">
                <Navbar />
                <main className="fixedBackground">
                    <NavbarMobile />
                    <LoginButton />
                    <ParagraphSpacer />
                    <ContactHeader />
                    <ParagraphSpacer />
                    <BlockSpacer />
                    <ContactContainer />
                    <BlockSpacer />
                    <Footer />
                    <ParagraphSpacer />
                </main>
            </div>
        );
    }
}

export default Contact;
